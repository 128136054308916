<template>
    <div :class="hasFreeText1 ? 'pt-3 pb-3' : 'pt-0'">
        <v-layout row v-if="hasFreeText1">
            <v-flex xs6 md3 py-1 offset-xs6 offset-md2>
                <v-row no-gutters>
                    <v-col cols="4" class="pt-2 pl-2">{{ freeTextLabel1 }}</v-col>
                    <v-text-field
                            :rules="[...validationRules.plainTextWithNumbers]"
                            :placeholder="$t('message.typeStatusText')"
                            autocomplete="off"
                            class="col-8"
                            clearable
                            dense
                            hide-details
                            solo
                            v-if="freeTextType1 == 'text'"
                            v-model="Internalremark__freetext1"
                    />
                    <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px"
                            ref="freeTextDateMenu1"
                            v-if="freeTextType1 == 'date'"
                            v-model="freeTextDateMenu1"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                    :rules="[...validationRules.formattedDate]"
                                    :value="computedFreeTextDateFormatted1"
                                    append-icon="event"
                                    class="custom"
                                    clearable
                                    dense
                                    hint="DD/MM/YY format"
                                    hide-details
                                    background-color="white"
                                    persistent-hint
                                    readonly
                                    solo
                                    v-on="on"
                                    @click:clear="Internalremark__freetext1 = null"
                            />
                        </template>
                        <v-date-picker
                                first-day-of-week="1"
                                locale-first-day-of-year="4"
                                show-week
                                v-model="Internalremark__freetext1"
                                @input="freeTextDateMenu1 = false"
                        />
                    </v-menu>
                    <v-select
                            :items="sortedCalendarWeeks"
                            class="col-8"
                            clearable
                            dense
                            hide-details
                            item-value="Calendarweek.title"
                            item-text="Calendarweek.title"
                            solo
                            v-if="freeTextType1 == 'week'"
                            v-model="Internalremark__freetext1"
                    >
                        <template v-slot:item="{item}">
                            <template v-if="item.Calendarweek.id === getCurrentWeek()">
                                <div class="d-flex flex-column" style="width: 100% !important;">
                                    <div class="font-weight-bold my-3">{{ getCurrentYear() }}</div>
                                    <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                </div>
                            </template>
                            <template v-if="item.Calendarweek.id > getCurrentWeek()">
                                <template v-if="[52,53].includes(item.Calendarweek.id)">
                                    <div class="d-flex flex-column" style="width: 100% !important;">
                                        <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                        <v-divider></v-divider>
                                        <div class="font-weight-bold mb-3">{{ getNextYear() }}</div>
                                    </div>
                                </template>
                                <template v-else>
                                    <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                </template>
                            </template>
                            <template v-if="item.Calendarweek.id < getCurrentWeek()">
                                <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                            </template>
                        </template>
                    </v-select>
                </v-row>
            </v-flex>
        </v-layout>
        <v-layout row v-if="hasFreeText2">
            <v-flex xs6 md3 py-1 offset-xs6 offset-md2>
                <v-row no-gutters>
                    <v-col cols="4" class="pt-2 pl-2">{{ freeTextLabel2 }}</v-col>
                    <v-text-field
                            :rules="[...validationRules.plainTextWithNumbers]"
                            :placeholder="$t('message.typeStatusText')"
                            autocomplete="off"
                            class="col-8"
                            clearable
                            dense
                            hide-details
                            solo
                            v-if="freeTextType2 == 'text'"
                            v-model="Internalremark__freetext2"
                    />
                    <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px"
                            ref="freeTextDateMenu2"
                            v-if="freeTextType2 == 'date'"
                            v-model="freeTextDateMenu2"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                    :rules="[...validationRules.formattedDate]"
                                    :value="computedFreeTextDateFormatted2"
                                    append-icon="event"
                                    class="custom"
                                    dense
                                    hint="DD/MM/YY format"
                                    hide-details
                                    background-color="white"
                                    persistent-hint
                                    readonly
                                    solo
                                    v-on="on"
                                    @click:clear="Internalremark__freetext2 = null"
                            />
                        </template>
                        <v-date-picker
                                first-day-of-week="1"
                                locale-first-day-of-year="4"
                                show-week
                                v-model="Internalremark__freetext2"
                                @input="freeTextDateMenu2 = false"
                        />
                    </v-menu>
                    <v-select
                            :items="sortedCalendarWeeks"
                            class="col-8"
                            clearable
                            dense
                            hide-details
                            item-value="Calendarweek.title"
                            item-text="Calendarweek.title"
                            solo
                            v-if="freeTextType2 == 'week'"
                            v-model="Internalremark__freetext2"
                    >
                        <template v-slot:item="{item}">
                            <template v-if="item.Calendarweek.id === getCurrentWeek()">
                                <div class="d-flex flex-column" style="width: 100% !important;">
                                    <div class="font-weight-bold my-3">{{ getCurrentYear() }}</div>
                                    <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                </div>
                            </template>
                            <template v-if="item.Calendarweek.id > getCurrentWeek()">
                                <template v-if="[52,53].includes(item.Calendarweek.id)">
                                    <div class="d-flex flex-column" style="width: 100% !important;">
                                        <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                        <v-divider></v-divider>
                                        <div class="font-weight-bold mb-3">{{ getNextYear() }}</div>
                                    </div>
                                </template>
                                <template v-else>
                                    <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                </template>
                            </template>
                            <template v-if="item.Calendarweek.id < getCurrentWeek()">
                                <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                            </template>
                        </template>
                    </v-select>
                </v-row>
            </v-flex>
        </v-layout>
        <v-layout row v-if="hasFreeText3">
            <v-flex xs6 md3 py-1 offset-xs6 offset-md2>
                <v-row no-gutters>
                    <v-col cols="4" class="pt-2 pl-2">{{ freeTextLabel3 }}</v-col>
                    <v-text-field
                            :rules="[...validationRules.plainTextWithNumbers]"
                            :placeholder="$t('message.typeStatusText')"
                            autocomplete="off"
                            class="col-8"
                            clearable
                            dense
                            hide-details
                            solo
                            v-if="freeTextType3 == 'text'"
                            v-model="Internalremark__freetext3"
                    />
                    <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px"
                            ref="freeTextDateMenu3"
                            v-if="freeTextType3 == 'date'"
                            v-model="freeTextDateMenu3"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                    :rules="[...validationRules.formattedDate]"
                                    :value="computedFreeTextDateFormatted3"
                                    append-icon="event"
                                    class="custom"
                                    dense
                                    hint="DD/MM/YY format"
                                    hide-details
                                    background-color="white"
                                    persistent-hint
                                    readonly
                                    solo
                                    v-on="on"
                                    @click:clear="Internalremark__freetext3 = null"
                            />
                        </template>
                        <v-date-picker
                                first-day-of-week="1"
                                locale-first-day-of-year="4"
                                show-week
                                v-model="Internalremark__freetext3"
                                @input="freeTextDateMenu3 = false"
                        />
                    </v-menu>
                    <v-select
                            :items="sortedCalendarWeeks"
                            class="col-8"
                            clearable
                            dense
                            hide-details
                            item-value="Calendarweek.title"
                            item-text="Calendarweek.title"
                            solo
                            v-if="freeTextType3 == 'week'"
                            v-model="Internalremark__freetext3"
                    >
                        <template v-slot:item="{item}">
                            <template v-if="item.Calendarweek.id === getCurrentWeek()">
                                <div class="d-flex flex-column" style="width: 100% !important;">
                                    <div class="font-weight-bold my-3">{{ getCurrentYear() }}</div>
                                    <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                </div>
                            </template>
                            <template v-if="item.Calendarweek.id > getCurrentWeek()">
                                <template v-if="[52,53].includes(item.Calendarweek.id)">
                                    <div class="d-flex flex-column" style="width: 100% !important;">
                                        <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                        <v-divider></v-divider>
                                        <div class="font-weight-bold mb-3">{{ getNextYear() }}</div>
                                    </div>
                                </template>
                                <template v-else>
                                    <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                </template>
                            </template>
                            <template v-if="item.Calendarweek.id < getCurrentWeek()">
                                <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                            </template>
                        </template>
                    </v-select>
                </v-row>
            </v-flex>
        </v-layout>
        <v-layout row v-if="hasFreeText4">
            <v-flex xs6 md3 py-1 offset-xs6 offset-md2>
                <v-row no-gutters>
                    <v-col cols="4" class="pt-2 pl-2">{{ freeTextLabel4 }}</v-col>
                    <v-text-field
                            :rules="[...validationRules.plainTextWithNumbers]"
                            :placeholder="$t('message.typeStatusText')"
                            autocomplete="off"
                            class="col-8"
                            clearable
                            dense
                            hide-details
                            solo
                            v-if="freeTextType4 == 'text'"
                            v-model="Internalremark__freetext4"
                    />
                    <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px"
                            ref="freeTextDateMenu3"
                            v-if="freeTextType4 == 'date'"
                            v-model="freeTextDateMenu4"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                    :rules="[...validationRules.formattedDate]"
                                    :value="computedFreeTextDateFormatted4"
                                    append-icon="event"
                                    class="custom"
                                    dense
                                    hint="DD/MM/YY format"
                                    hide-details
                                    background-color="white"
                                    persistent-hint
                                    readonly
                                    solo
                                    v-on="on"
                                    @click:clear="Internalremark__freetext4 = null"
                            />
                        </template>
                        <v-date-picker
                                first-day-of-week="1"
                                locale-first-day-of-year="4"
                                show-week
                                v-model="Internalremark__freetext4"
                                @input="freeTextDateMenu4 = false"
                        />
                    </v-menu>
                    <v-select
                            :items="sortedCalendarWeeks"
                            class="col-8"
                            clearable
                            dense
                            hide-details
                            item-value="Calendarweek.title"
                            item-text="Calendarweek.title"
                            solo
                            v-if="freeTextType4 == 'week'"
                            v-model="Internalremark__freetext4"
                    >
                        <template v-slot:item="{item}">
                            <template v-if="item.Calendarweek.id === getCurrentWeek()">
                                <div class="d-flex flex-column" style="width: 100% !important;">
                                    <div class="font-weight-bold my-3">{{ getCurrentYear() }}</div>
                                    <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                </div>
                            </template>
                            <template v-if="item.Calendarweek.id > getCurrentWeek()">
                                <template v-if="[52,53].includes(item.Calendarweek.id)">
                                    <div class="d-flex flex-column" style="width: 100% !important;">
                                        <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                        <v-divider></v-divider>
                                        <div class="font-weight-bold mb-3">{{ getNextYear() }}</div>
                                    </div>
                                </template>
                                <template v-else>
                                    <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                </template>
                            </template>
                            <template v-if="item.Calendarweek.id < getCurrentWeek()">
                                <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                            </template>
                        </template>
                    </v-select>
                </v-row>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import {mapFields} from "vuex-map-fields";
    import {mapGetters} from "vuex";
    import {internalRemarks, validationRules} from "../../store/modules/appic/constants";
    import {
        formatDate,
        getCurrentWeek,
        getCurrentYear,
        getDateRangeOfWeek,
        getDateRangeOfWeekWithYear,
        getNextYear,
        yearHasExtraWeek
    } from "Helpers/helpers";

    export default {
        name: "InternalRemark",
        data() {
            return {
                freeTextDateMenu1: false,
                freeTextDateMenu2: false,
                freeTextDateMenu3: false,
                freeTextDateMenu4: false,
                freeTextLabel1: null,
                freeTextLabel2: null,
                freeTextLabel3: null,
                freeTextLabel4: null,
                freeTextType1: 'text',
                freeTextType2: 'text',
                freeTextType3: 'text',
                freeTextType4: 'text',
            }
        },
        computed: {
            validationRules() {
                return validationRules
            },
            ...mapFields('shipment',{
                Shipment__internalremark_id: 'current.Shipment.internalremark_id',
                Internalremark__freetext1: 'current.Shipment.freetext1',
                Internalremark__freetext2: 'current.Shipment.freetext2',
                Internalremark__freetext3: 'current.Shipment.freetext3',
                Internalremark__freetext4: 'current.Shipment.freetext4',
            }),
            ...mapGetters([
                'calendarWeeks',
                'internalRemarks',
                'validationRules'
            ]),
            computedFreeTextDateFormatted1 () {
                return formatDate(this.Internalremark__freetext1)
            },
            computedFreeTextDateFormatted2 () {
                return formatDate(this.Internalremark__freetext2)
            },
            computedFreeTextDateFormatted3 () {
                return formatDate(this.Internalremark__freetext3)
            },
            computedFreeTextDateFormatted4 () {
                return formatDate(this.Internalremark__freetext4)
            },
            hasFreeText1 () {
                let internalremark = this.internalRemarks.find(item=>item.Internalremark.id == this.Shipment__internalremark_id)
                if(internalremark?.Internalremark?.freetext1 != null){
                    this.freeTextLabel1 = internalremark?.Internalremark?.freetext1_label
                    this.freeTextType1 = internalremark?.Internalremark?.freetext1_type
                    return true
                } else {
                    return false
                }
            },
            hasFreeText2 () {
                let internalremark = this.internalRemarks.find(item=>item.Internalremark.id == this.Shipment__internalremark_id)
                if(internalremark?.Internalremark?.freetext2 != null){
                    this.freeTextLabel2 = internalremark?.Internalremark?.freetext2_label
                    this.freeTextType2 = internalremark?.Internalremark?.freetext2_type
                    return true
                } else {
                    return false
                }
            },
            hasFreeText3 () {
                let internalremark = this.internalRemarks.find(item=>item.Internalremark.id == this.Shipment__internalremark_id)
                if(internalremark?.Internalremark?.freetext3 != null){
                    this.freeTextLabel3 = internalremark?.Internalremark?.freetext3_label
                    this.freeTextType3 = internalremark?.Internalremark?.freetext3_type
                    return true
                } else {
                    return false
                }
            },
            hasFreeText4 () {
                let internalremark = this.internalRemarks.find(item=>item.Internalremark.id == this.Shipment__internalremark_id)
                if(internalremark?.Internalremark?.freetext4 != null){
                    this.freeTextLabel4 = internalremark?.Internalremark?.freetext4_label
                    this.freeTextType4 = internalremark?.Internalremark?.freetext4_type
                    return true
                } else {
                    return false
                }
            },
            sortedCalendarWeeks: function () {
                const currentWeek = getCurrentWeek();
                let nextWeeks = []
                let previousWeeks = []
                this.calendarWeeks.forEach(cw => {
                    if(cw.Calendarweek.id >= currentWeek){
                        nextWeeks.push(cw)
                    } else {
                        previousWeeks.push(cw)
                    }
                })

                //check if current year has week 53
                if(yearHasExtraWeek(getCurrentYear)){
                    const calendarObj = {Calendarweek: {id:53, title: 'WK 53', dateRange: ''}}
                    nextWeeks.push(calendarObj)
                }

                //generate date range values
                nextWeeks.forEach((item, idx) => {
                    nextWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeek(item.Calendarweek.id)
                })
                previousWeeks.forEach((item, idx) => {
                    previousWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeekWithYear(item.Calendarweek.id, getNextYear())
                })
                const weeks = [...nextWeeks, ...previousWeeks]
                return weeks
            }
        },
        methods: {
            getCurrentWeek,
            getCurrentYear,
            getNextYear,
            getDateRangeOfWeek,
            getDateRangeOfWeekWithYear
        }
    }
</script>

<style scoped>

</style>